<template>
  <form @submit.prevent="save" class="w-m-content" v-if="!loading && !errors && !success">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin" style="color: #293e52">Cadastro de {{capitalizedType}}</p>
      <div class="m-t-xs font-13 text-grey-7" style="color: #748396; padding-right: 20px; line-height: 1">Cadastre um {{type}} para aparecer em destaque no seu site</div>
    </div>
    <div class="w-m-body e-input-modern">
      <div style="min-height: 400px">
        <e-row class="m-b-sm font-bold">
          Título:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input autofocus simple-border required :placeholder="`Digite o título para identificar o ${type}`" v-model="data.title" />
          </e-col>
        </e-row>

        <e-row mr>
          <e-col>
            <e-row class="m-b-sm m-t-n-xs font-bold">
              Posição:
            </e-row>
            <e-row class="m-b-sm">
              <e-col>
                <erp-input simple-border required placeholder="" v-model="data.position" type="number"/>
              </e-col>
            </e-row>
          </e-col>
          <e-col>
            <e-row class="m-b-sm m-t-n-xs font-bold">
              Seção:
            </e-row>
            <e-row class="m-b-sm">
              <e-col>
                <erp-input simple-border placeholder="" v-model="data.secao" />
              </e-col>
            </e-row>
          </e-col>
        </e-row>

        <e-row class="m-b-sm m-t-n-xs font-bold">
          Link:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input simple-border required type="text" :placeholder="`Digite o link do site que será aberto ao clicar no ${type}`"
                       v-model="data.link" />
          </e-col>
        </e-row>

        <e-row mr>
          <e-col>
            <e-row class="m-b-sm m-t-n-xs font-bold">
              Início da exibição:
            </e-row>
            <e-row class="m-b-sm">
              <e-col>
                <erp-input simple-border required type="text" placeholder="dd/mm/yyyy"
                           v-model="data.dateStartExhibition" v-mask="'##/##/####'"/>
              </e-col>
            </e-row>
          </e-col>
          <e-col>
            <e-row class="m-b-sm m-t-n-xs font-bold">
              Fim da exibição:
            </e-row>
            <e-row class="m-b-sm">
              <e-col>
                <erp-input simple-border required type="text" placeholder="dd/mm/yyyy"
                           v-model="data.dateEndExhibition" v-mask="'##/##/####'"/>
              </e-col>
            </e-row>
          </e-col>
        </e-row>

        <e-row class="m-b-sm m-t-n-xs font-bold">
          Sites
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <multisite v-model="data.sites" />
          </e-col>
        </e-row>

        <e-row class="m-b-sm">
          <e-col>
            <imagem label="Preview" legend="" ref="imagem" :image="data.image" @update="updateImagem" />
          </e-col>
        </e-row>

      </div>
    </div>
    <div class="w-m-footer text-right">
      <u-btn type="submit" label="Cadastrar" no-caps class="b-radius-3px" />
    </div>
  </form>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">{{capitalizedType}} {{data.id ? 'atualizado' : 'cadastrado'}} com sucesso!</h5>

    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
  import ERow from '../../../../layout/components/Row'
  import {ErpInput} from 'uloc-vue-plugin-erp'
  import ECol from '../../../../layout/components/Col'
  import WindowSuccess from '../../../../layout/window/Success'
  import TimerCloseWindow from '../../../../layout/window/TimerClose'
  import WindowLoading from '../../../../layout/window/Loading'
  import {newBanner, updateBanner, updateFotoBanner, find} from '../../../../../domain/banner/services'
  import {TYPE_BANNER, types} from '../../../../../domain/banner/helpers'
  // import {fileToBase64} from '../../../../../utils/fileToBase64'
  import Imagem from '../../../../../reuse/upload/Imagem'
  import {date} from 'uloc-vue'
  import Multisite from "@/components/website/components/Multisite";

  export default {
    name: 'BannerWindowModern',
    components: {Multisite, Imagem, WindowLoading, TimerCloseWindow, WindowSuccess, ECol, ERow, ErpInput},
    props: ['id', 'type'],
    data () {
      return {
        data: {
          title: '',
          link: '',
          hasVideo: '',
          position: 1,
          dateStartExhibition: '',
          dateEndExhibition: '',
          type: TYPE_BANNER,
          secao: null,
          sites: []
        },
        loading: !!this.id,
        errors: null,
        success: false,
        status: null
      }
    },
    created () {
      this.data.type = types[this.type]
    },
    mounted () {
      console.log('Teste', this.id)
      this.load()
    },
    computed: {
      capitalizedType () {
        if (!this.type) return ''
        return `${this.type.charAt(0).toUpperCase()}${this.type.slice(1)}`
      }
    },
    methods: {
      load () {
        const id = this.data.id || this.id
        if (!id) return

        this.loading = true
        this.status = `Carregando dados do ${this.type}`
        find(id)
                .then(result => {
                  this.data = result.data
                  console.log(this.data)

                  if (this.data.dateStartExhibition && this.data.dateStartExhibition.date) {
                    this.data.dateStartExhibition = date.formatDate(this.data.dateStartExhibition.date, 'DD/MM/YYYY')
                  }

                  if (this.data.dateEndExhibition && this.data.dateEndExhibition.date) {
                    this.data.dateEndExhibition = date.formatDate(this.data.dateEndExhibition.date, 'DD/MM/YYYY')
                  }

                  if (this.data.sites) {
                    this.data.sites = this.data.sites.map(s => s.id)
                  }

                  this.loading = false
                })
                .catch(error => {
                  this.errors = error.data.message
                  this.loading = false
                })
      },
      save () {
        console.log('Save')
        this.loading = true

        this.status = `Processando dados do ${this.type}`
        let method = this.data.id ? updateBanner(this.data.id, this.data) : newBanner(this.data)
        method
                .then(({data}) => {
                  this.data.id = data.id
                  const cbSuccess = () => {
                    this.success = true
                    this.$nextTick(() => {
                      // this.$refs.timer.start()
                      window.setTimeout(() => {
                        this.loading = false
                        this.success = false
                        this.load()
                      }, 3000)
                      this.$uloc.window.emit(this.$root.wid, 'update', this.props)
                    })
                  }
                  // Processa foto
                  if (this.deleteFoto) {
                    console.log('Deleta Foto')
                  } else if (this.$imagem) {
                    this.status = `Analisando e processando foto do ${this.type}`
                    updateFotoBanner(this.data.id, {data: this.$imagem, filename: 'foto'})
                            .then(({data}) => {
                              cbSuccess()
                              delete this.$imagem
                            })
                            .catch((error) => {
                              alert(`Impossível enviar a imagem do ${this.type}, mas os demais dados foram processados com sucesso!`)
                              console.error(error)
                              cbSuccess()
                            })
                  } else {
                    cbSuccess()
                  }

                })
                .catch(error => {
                  console.log(error)
                  this.success = false
                  this.errorMessage = error.data.message
                  // this.errors = this.systemError(error)
                  this.$nextTick(() => {
                    this.loading = false
                    this.alertApiError(error)
                    // this.error = true
                  })
                })
      },
      updateImagem (file) {
        this.$imagem = file
      }
    }
  }
</script>
